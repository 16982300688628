<template>
  <div class="container-fluid">
    <div class="row mt-4">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col ml-n2">
                <h4 class="mb-1">
                  <a
                    >{{ $t('purchaseDiscounts.show.purchaseDiscount') }}
                    <strong> #{{ item.code }}</strong></a
                  >
                </h4>
              </div>
              <div class="col-auto">
                <router-link
                  :to="path + '/' + item.id + '/edit'"
                  class="btn btn-sm btn-primary"
                  :title="$t('purchaseDiscounts.show.Modification')"
                >
                  <i class="fas fa-edit"></i>
                </router-link>
                <a
                  @click="print(item.id)"
                  class="btn btn-sm btn-primary"
                  :title="$t('purchaseDiscounts.show.Printing')"
                >
                  <i class="fas fa-print"></i>
                </a>
                <a
                  :href="$linkGnirator('/purchaseDiscounts/' + item.id + '/pdf')"
                  download
                  class="btn btn-sm btn-primary"
                  :title="$t('purchaseDiscounts.show.DownloadPDF')"
                >
                  <i class="fas fa-download"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-8">
        <div class="card card-body p-5 invoice-show">
          <PurchaseDiscounts :item="item" />
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="card">
          <div class="card-header">
            <h4 class="card-header-title">#{{ item.code }}</h4>
          </div>
          <div class="card-body">
            <ul class="list-unstyled">
              <li>
                <strong>{{ $t('invoices.show.createdIn') }}</strong> :
                {{ item.created_at }}
              </li>
              <li>
                <strong>{{ $t('invoices.show.LastUpdate') }}</strong> :
                {{ item.updated_at }}
              </li>
              <li v-if="item.client_type == 0">
                <strong>{{ $t('invoices.show.Client') }}</strong> :
                <router-link :to="'/customers/' + item.customer.id">
                  {{ item.customer.name }}</router-link
                >
              </li>
              <li v-if="item.client_type == 1">
                <strong>{{ $t('invoices.show.Client') }}</strong> :
                <router-link :to="'/suppliers/' + item.supplier.id">
                  {{ item.supplier.name }}</router-link
                >
              </li>
              <li>
                <strong>{{ $t('invoices.show.Administrator') }}</strong> :
                {{ item.user.name }}
              </li>
            </ul>
          </div>
        </div>

        <showFile />
      </div>
    </div>
    <print v-if="printInvoice" :options="$option"></print>
  </div>
</template>
<script>
import axios from 'axios'
import showFile from '@/elements/show/showFile.vue'
import print from '@/print/purchasePayment.vue'
import PurchaseDiscounts from '@/print/components/purchaseDiscounts.vue'
export default {
  data() {
    return {
      path: '/purchaseDiscounts',
      item: {},

      printInvoice: false
    }
  },
  mounted() {
    this.getitem()
  },
  methods: {
    getitem() {
      axios.get(this.$linkGnirator(this.path + '/' + this.$route.params.id)).then((response) => {
        this.item = response.data
      })
    },
    print(id) {
      this.printInvoice = id
    }
  },
  components: {
    print,
    showFile,
    PurchaseDiscounts
  }
}
</script>
